"use client";

import classNames from "classnames";
import React, { forwardRef } from "react";
import {
  Input as MTInput,
  InputProps as MTInputProps,
} from "@material-tailwind/react";
import { Oval } from "react-loader-spinner";
import { IconSvg, IconTypes } from "../Helpers/ButtonIconSvg";
import BeatLoader from "react-spinners/BeatLoader";

interface InputProps extends MTInputProps {
  icon?: IconTypes;
  visible?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  button?: any;
  inputClassName?: any;
  className?: any;
  buttonSize?: "md" | "lg";
  iconClass?: any;
  isLoading?: boolean;
  error?: boolean;
  success?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      icon,
      visible = true,
      fullWidth,
      buttonSize = "md",
      button = false,
      className = false,
      inputClassName = false,
      iconClass = false,
      isLoading,
      error = false,
      success = false,
      ...inputProps
    },
    ref
  ) => {
    const _wrapperClass = classNames(
      "relative rounded-lg bg-white flex-row items-center justify-start gap-[12px] inline-flex flex-grow text-black",
      { "w-full": fullWidth },
      { "py-1": buttonSize === "lg" },
      { "relative flex w-full p-2 flex-nowrap": button },
      { "border-2 border-red-500 [*>*]:!text-red": error },
      { "border-2 border-green [*>*]:!text-green": success },
      { [className]: className }
    );

    const _inputClass = classNames(
      "flex-grow [border:none] transition-[width] duration-500 text-base md:text-xl relative text-black placeholder-gray-light placeholder:!opacity-100 text-left !bg-transparent !bg-none inline-block shrink-0 focus:outline-none w-auto !border-0 disabled:!bg-[transparent] py-2.5 px-4 pr-12 focus:!shadow-none focus:!outline-none !outline-none",
      { "text-20": buttonSize === "lg" },
      { [inputClassName]: inputClassName }
    );

    const defaultProps = {
      className: _inputClass,
      // labelProps: {
      //   className: "before:content-none after:content-none hidden",
      // },
      // containerProps: {
      //   className: "!min-w-0 flex-grow !w-auto h-auto",
      // },
      //icon: icon && <IconSvg type={icon} />,
    };

    if (isLoading) {
      inputProps["disabled"] = true;
    }

    const mtInputProps = {
      ...defaultProps,
      ...(inputProps as any),
      ref: ref,
    };

    return (
      <div
        className={_wrapperClass}
        style={{ visibility: visible ? "visible" : "hidden" }}
      >
        <input {...mtInputProps} ref={ref} />

        {icon && (
          <span
            className={classNames(
              "mr-3 absolute right-0 top-[50%] translate-y-[-50%]",
              {
                [iconClass]: iconClass,
              }
            )}
          >
            <IconSvg type={icon} />
          </span>
        )}

        {isLoading && <BeatLoader color="#183e4f" />}
        {!isLoading && button && <div className="">{button}</div>}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
