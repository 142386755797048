import { create } from "zustand";
import { produce } from "immer";

type Modal = {
  title: string;
  isOpen: boolean;
  content?: any;
  onOpen: (data?: any) => void;
  onClose: () => void;
  setTitle: (title: string) => void;
  setContent: (content: React.ReactNode) => void;
}

interface ModalStore {
  loginModal: Modal;
  financeModal: Modal;
  agreementModal: Modal;
  myAccountModal: Modal;
}

const useModalStore = create<ModalStore>((set) => ({
  myAccountModal: {
    title: "",
    isOpen: false,
    setContent: (content: React.ReactNode) =>
      set(
        produce((state) => {
          state.myAccountModal.content = content;
        }
        )),
    onOpen: () =>
      set(
        produce((state) => {
          state.myAccountModal.isOpen = true;
        })
      ),
    onClose: () =>
      set(
        produce((state) => {
          state.myAccountModal.isOpen = false;
        })
      ),
    setTitle: (title: string) =>
      set(
        produce((state) => {
          state.myAccountModal.title = title;
        })
      )

  },
  agreementModal: {
    title: "",
    isOpen: false,
    setContent: (content: React.ReactNode) =>
      set(
        produce((state) => {
          state.agreementModal.content = content;
        }
        )),
    onOpen: () =>
      set(
        produce((state) => {
          state.agreementModal.isOpen = true;
        })
      ),
    onClose: () =>
      set(
        produce((state) => {
          state.agreementModal.isOpen = false;
        })
      ),
    setTitle: (title: string) =>
      set(
        produce((state) => {
          state.agreementModal.title = title;
        })
      )
  },
  loginModal: {
    title: "Logga in för att lägga bud",
    isOpen: false,
    setContent: (content: React.ReactNode) =>
      set(
        produce((state) => {
          state.loginModal.content = content;
        }
        )),
    onOpen: () =>
      set(
        produce((state) => {
          state.loginModal.isOpen = true;
        })
      ),
    onClose: () =>
      set(
        produce((state) => {
          state.loginModal.isOpen = false;
        })
      ),
    setTitle: (title: string) =>
      set(
        produce((state) => {
          state.loginModal.title = title;
        })
      )
  },
  financeModal: {
    title: "Ansök om finansiering",
    isOpen: false,
    setContent: (content: React.ReactNode) =>
      set(
        produce((state) => {
          state.financeModal.content = content;
        }
        )),
    onOpen: () =>
      set(
        produce((state) => {
          state.financeModal.isOpen = true;
        })
      ),
    onClose: () =>
      set(
        produce((state) => {
          state.financeModal.isOpen = false;
        })
      ),
    setTitle: (title: string) =>
      set(
        produce((state) => {
          state.financeModal.title = title;
        })
      )
  },
}));

export default useModalStore;
