import moment from "moment";

export const formatPrice = (price: number) => {
    if (!price || isNaN(price)) {
        return "0 kr";
    }

    return new Intl.NumberFormat("sv-SE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(price) + " kr";
};

export const getSwedishDate = (date: Date | string | number) => {
    return parseToDate(date).toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm"
    });
};

export const parseDate = (date: string) => {
    return moment(date).valueOf();
}

export const parseToDate = (date: string | number | Date) => {
    return moment(date).toDate();
}