"use client";

import { Button as MTButton } from "@material-tailwind/react";
import React from "react";
import classNames from "classnames";
import type { ButtonProps } from "@material-tailwind/react";
import { IconSvg, IconTypes } from "../Helpers/ButtonIconSvg";

// For Gutenberg
export const iconTypes = [
  { value: "arrow-right", label: "Pil höger" },
  { value: "download", label: "Ladda ner" },
  { value: "arrow-down", label: "Pil ner" },
];

interface ButtonElemenProps extends ButtonProps {
  size?: "sm" | "md" | "lg";
  icon?: IconTypes | undefined; // React.ReactNode;
  textColor?: string;
  bgColor?: string;
  borderColor?: string;
  fullWidth?: boolean;
  textCenter?: boolean;
  className?: any;
  isLoading?: boolean;
  buttonProps?: ButtonProps | any;
  iconDirection?: "left" | "right";
}

const Button: React.FC<ButtonElemenProps> = ({
  children,
  size = "md",
  textColor = false,
  bgColor = false,
  fullWidth = false,
  textCenter = false,
  className = false,
  borderColor = "transparent",
  isLoading = false,
  iconDirection = "right",
  icon,
  ...buttonProps
}) => {
  const _buttonClass = classNames(
    "group relative disabled:!opacity-50 cursor-pointer font-light py-[9px] px-[18px] rounded-lg flex flex-row items-end justify-start box-border gap-[9px] hover:bg-opacity-[90%] transiton-all duration-500 tracking-[-0.01em] leading-[150%] normal-case md:space-x-[9px] !h-full items-center group text-white !opacity-100 border-0",
    {
      "w-full": fullWidth,
      "bg-blue": !bgColor,
      [`bg-${bgColor}`]: bgColor !== false,
      [`text-${textColor}`]: textColor !== false,
      [`!border-2 !border-solid border-${borderColor}`]: borderColor,
      "text-white": !textColor,
      "text-base": size === "md",
      "text-base font-semibold": size === "md",
      "text-xl font-bold": size === "lg",
      "justify-center": textCenter,
      "!text-14 font-semibold !px-2 !px-4": size === "sm",
      [className]: className,
    }
  );

  const iconClass = classNames("z-20", {
    "order-0": iconDirection === "left",
    "order-1 ml-auto": iconDirection === "right",
  });

  return (
    <MTButton
      className={_buttonClass}
      {...(buttonProps as any)}
      variant="outlined"
    >
      <span className="order-1 z-20">{children}</span>
      {isLoading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}

      {icon && !isLoading && (
        <span className={iconClass}>
          <IconSvg type={icon} />
        </span>
      )}
    </MTButton>
  );
};

export default Button;
