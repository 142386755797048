// useServerTimeStore.ts
import { parseDate, parseToDate } from "@/lib/utils";
import { create } from "zustand";

interface ServerTimeState {
    initialServerTime: number | null;
    initialPerfTime: number | null;
    setServerTime: (serverTime: string | null) => void;
    getServerTime: () => Date | null;
}

const useServerTimeStore = create<ServerTimeState>((set, get) => ({
    initialServerTime: null,
    initialPerfTime: null,
    setServerTime: (serverTime: any) => {
        set({
            initialServerTime: serverTime && parseDate(serverTime),
            initialPerfTime: performance.now(),
        });
    },
    getServerTime: () => {
        const { initialServerTime, initialPerfTime } = get();
        if (initialServerTime === null || initialPerfTime === null) return null;
        const elapsed = performance.now() - initialPerfTime;
        return parseToDate(initialServerTime + elapsed);
    },
}));

export default useServerTimeStore;