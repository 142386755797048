export type IconTypes =
  | "arrow-right"
  | "download"
  | "arrow-down"
  | "arrow-up"
  | "chevron-down"
  | "sort-arrow"
  | "bankid"
  | "search";

export const IconSvg = ({ type }: { type: IconTypes }) => {
  let svg;

  switch (type) {
    case "bankid":
      svg = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 13 13"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <g>
            <path d="M4.169,6.789l0.533,-3.354l-0.569,0c-0.266,0 -0.609,-0.149 -0.709,-0.423c-0.033,-0.093 -0.109,-0.412 0.33,-0.722c0.157,-0.109 0.258,-0.23 0.278,-0.323c0.021,-0.096 -0.004,-0.181 -0.072,-0.245c-0.097,-0.093 -0.286,-0.146 -0.528,-0.146c-0.407,0 -0.694,0.234 -0.722,0.404c-0.02,0.125 0.077,0.225 0.161,0.29c0.254,0.189 0.315,0.463 0.158,0.722c-0.162,0.266 -0.512,0.439 -0.887,0.443l-0.581,0c-0.048,0.327 -0.839,5.334 -0.899,5.729l3.149,0c0.028,-0.178 0.173,-1.125 0.371,-2.375l-0.013,0Z" />
            <path d="M8.387,0.5l-3.206,0l-0.427,2.713l0.544,0c0.299,0 0.581,-0.137 0.702,-0.334c0.04,-0.065 0.056,-0.121 0.056,-0.174c0,-0.113 -0.076,-0.197 -0.153,-0.254c-0.21,-0.157 -0.254,-0.322 -0.254,-0.439c0,-0.024 0,-0.045 0.004,-0.065c0.044,-0.286 0.431,-0.596 0.943,-0.596c0.307,0 0.541,0.072 0.682,0.205c0.125,0.117 0.173,0.282 0.137,0.456c-0.044,0.205 -0.25,0.375 -0.367,0.459c-0.31,0.218 -0.27,0.408 -0.25,0.464c0.065,0.169 0.311,0.278 0.5,0.278l0.826,0l0,0.004c1.129,0.008 1.734,0.528 1.545,1.738c-0.178,1.125 -1.041,1.608 -2.069,1.617l-0.407,2.596l0.601,0c2.536,0 4.608,-1.629 5.015,-4.201c0.504,-3.193 -1.52,-4.467 -4.422,-4.467Z" />
            <path d="M8.387,0.5l-3.206,0l-0.427,2.713l0.544,0c0.299,0 0.581,-0.137 0.702,-0.334c0.04,-0.065 0.056,-0.121 0.056,-0.174c0,-0.113 -0.076,-0.197 -0.153,-0.254c-0.21,-0.157 -0.254,-0.322 -0.254,-0.439c0,-0.024 0,-0.045 0.004,-0.065c0.044,-0.286 0.431,-0.596 0.943,-0.596c0.307,0 0.541,0.072 0.682,0.205c0.125,0.117 0.173,0.282 0.137,0.456c-0.044,0.205 -0.25,0.375 -0.367,0.459c-0.31,0.218 -0.27,0.408 -0.25,0.464c0.065,0.169 0.311,0.278 0.5,0.278l0.826,0l0,0.004c1.129,0.008 1.734,0.528 1.545,1.738c-0.178,1.125 -1.041,1.608 -2.069,1.617l-0.407,2.596l0.601,0c2.536,0 4.608,-1.629 5.015,-4.201c0.504,-3.193 -1.52,-4.467 -4.422,-4.467Z" />
            <path d="M0.493,10.321l1.286,0c0.548,0 0.681,0.278 0.641,0.532c-0.032,0.206 -0.174,0.359 -0.415,0.46c0.306,0.117 0.427,0.298 0.383,0.584c-0.057,0.359 -0.367,0.625 -0.775,0.625l-1.463,0l0.343,-2.201Zm0.85,0.911c0.25,0 0.367,-0.133 0.391,-0.29c0.025,-0.17 -0.052,-0.286 -0.302,-0.286l-0.222,0l-0.088,0.576l0.221,0Zm-0.137,0.952c0.258,0 0.407,-0.105 0.444,-0.319c0.028,-0.185 -0.077,-0.294 -0.327,-0.294l-0.254,0l-0.097,0.617l0.234,0l0,-0.004Z" />
            <path d="M4.19,12.538c-0.335,0.024 -0.496,-0.012 -0.577,-0.157c-0.177,0.109 -0.375,0.165 -0.584,0.165c-0.379,0 -0.512,-0.197 -0.476,-0.415c0.016,-0.105 0.076,-0.206 0.173,-0.29c0.21,-0.182 0.726,-0.206 0.927,-0.343c0.017,-0.153 -0.044,-0.209 -0.233,-0.209c-0.222,0 -0.408,0.072 -0.726,0.29l0.077,-0.5c0.274,-0.198 0.54,-0.29 0.846,-0.29c0.391,0 0.738,0.161 0.673,0.588l-0.076,0.484c-0.028,0.169 -0.02,0.222 0.169,0.226l-0.193,0.451Zm-0.581,-0.758c-0.177,0.113 -0.508,0.093 -0.544,0.327c-0.016,0.109 0.052,0.189 0.161,0.189c0.105,0 0.234,-0.044 0.339,-0.117c-0.008,-0.04 -0.004,-0.08 0.008,-0.157l0.036,-0.242Z" />
            <path d="M4.815,10.817l0.669,0l-0.037,0.222c0.214,-0.182 0.375,-0.25 0.585,-0.25c0.375,0 0.548,0.229 0.488,0.604l-0.173,1.125l-0.67,0l0.145,-0.931c0.029,-0.169 -0.024,-0.25 -0.153,-0.25c-0.105,0 -0.201,0.056 -0.294,0.181l-0.153,0.996l-0.67,0l0.263,-1.697Z" />
            <path d="M7.04,10.321l0.669,0l-0.169,1.08l0.641,-0.584l0.826,0l-0.822,0.725l0.661,0.976l-0.842,0l-0.508,-0.786l-0.009,0l-0.12,0.786l-0.67,0l0.343,-2.197Z" />
            <path d="M9.411,10.321l0.77,0l-0.339,2.197l-0.77,0l0.339,-2.197Z" />
            <path d="M10.556,10.321l1.1,0c0.851,0 1.097,0.617 1.016,1.129c-0.077,0.5 -0.472,1.068 -1.217,1.068l-1.242,0l0.343,-2.197Zm0.713,1.673c0.375,0 0.581,-0.185 0.641,-0.576c0.044,-0.291 -0.044,-0.577 -0.459,-0.577l-0.206,0l-0.177,1.153l0.201,0Z" />
          </g>
        </svg>
      );
      break;
    case "search":
      svg = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g mask="url(#mask0_55_2698)">
            <path
              d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.14583 15.3708 4.8875 14.1125C3.62917 12.8542 3 11.3167 3 9.5C3 7.68333 3.62917 6.14583 4.8875 4.8875C6.14583 3.62917 7.68333 3 9.5 3C11.3167 3 12.8542 3.62917 14.1125 4.8875C15.3708 6.14583 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8125 13.5625 12.6875 12.6875C13.5625 11.8125 14 10.75 14 9.5C14 8.25 13.5625 7.1875 12.6875 6.3125C11.8125 5.4375 10.75 5 9.5 5C8.25 5 7.1875 5.4375 6.3125 6.3125C5.4375 7.1875 5 8.25 5 9.5C5 10.75 5.4375 11.8125 6.3125 12.6875C7.1875 13.5625 8.25 14 9.5 14Z"
              fill="currentColor"
            />
          </g>
        </svg>
      );

      break;
    case "arrow-right":
      svg = (
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          className="group-hover:translate-x-[2px] duration-300 transition-all"
        >
          <path
            d="M1.4 12L0 10.6L4.6 6L0 1.4L1.4 0L7.4 6L1.4 12Z"
            fill="currentColor"
          />
        </svg>
      );
      break;
    case "download":
      svg = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          className="-order-1"
        >
          <path
            d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z"
            fill="currentColor"
          />
        </svg>
      );
      break;
    case "arrow-down":
      svg = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Chevron">
            <path
              id="Union"
              d="M16.59 8.59003L12 13.17L7.41 8.59003L6 10L12 16L18 10L16.59 8.59003Z"
              fill="currentColor"
            />
          </g>
        </svg>
      );

      break;

    case "arrow-up":
      svg = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          className="transform rotate-180"
        >
          <g id="Chevron">
            <path
              id="Union"
              d="M16.59 8.59003L12 13.17L7.41 8.59003L6 10L12 16L18 10L16.59 8.59003Z"
              fill="currentColor"
            />
          </g>
        </svg>
      );

      break;
    case "chevron-down":
      svg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 neutral-dark-gray transition-all"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      );
      break;
    case "sort-arrow":
      svg = (
        <svg
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-[10px] h-[10px] md:w-[15px] md:h-[17px]"
        >
          <path
            d="M7.0833 0L0 7.0833H14.1667L7.0833 0ZM0 9.9167L7.0833 17L14.1667 9.9167H0Z"
            fill="#2D2D32"
          />
        </svg>
      );
      break;
    default:
      svg = null;
  }

  return svg;
};
