"use client"

import { create } from "zustand";
import { produce } from "immer";
import { persist, createJSONStorage } from "zustand/middleware";
import CryptoJS from "crypto-js";

const USER_STORE_KEY = 'aD93Jdf!s93Jd1@#sA9f1ks9j3$#d9fsJ';
const USER_STORE_SALT = 'sKf92sdf!sKDf9j32@!Jfkd93$#sKj39#D';

interface User {
    id: number;
    email: string;
}

interface UserStoreProps {
    setUser: (session: { user: User; accessToken: string }) => void;
    setUserInfo: (userInfo: any) => void;
    setAgreementStatus: (status: boolean) => void;
    setUserHasVerified: (status: boolean) => void;
    clearSession: () => void;
    validateSession: () => void;
    setFetchKeys: (keys: string[]) => void;
    fetchKeys: string[];
    userInfo: any;
    userAgreementStatus: boolean;
    user: User | null;
    accessToken: string;
}

// Encryption and Decryption Functions
const encryptData = (data: string) => {
    const encrypted = CryptoJS.AES.encrypt(data, USER_STORE_KEY + USER_STORE_SALT).toString();
    return btoa(encrypted);
};

const decryptData = (encryptedData: string) => {
    const bytes = CryptoJS.AES.decrypt(atob(encryptedData), USER_STORE_KEY + USER_STORE_SALT);
    return bytes.toString(CryptoJS.enc.Utf8);
};

const encryptedStorage = {
    getItem: (name: string) => {
        const encryptedState = localStorage.getItem(name);
        if (encryptedState) {
            try {
                return JSON.parse(decryptData(encryptedState));
            } catch (e) {
                console.error("Decryption failed:", e);
                return null;
            }
        }
        return null;
    },
    setItem: (name: string, state: any) => {
        const encryptedState = encryptData(JSON.stringify(state));
        localStorage.setItem(name, encryptedState);
    },
    removeItem: (name: string) => {
        localStorage.removeItem(name);
    }
};

// Zustand Store with Broadcast Channel Middleware
// @ts-ignore: Suppress zustand type compatibility error
const useUserStore = create<UserStoreProps>(
    //shared(
    persist(
        (set: any, get: any) => ({
            user: null,
            userInfo: null,
            userHasVerified: false,
            accessToken: "",
            userAgreementStatus: null,
            setFetchKeys: (newKeys: { [key: string]: any }) => {
                set(
                    produce((state: any) => {
                        state.fetchKeys = {
                            ...state.fetchKeys,
                            ...newKeys
                        };
                    })
                );
            },
            setUserHasVerified: (status: boolean) => {
                set(
                    produce((state: any) => {
                        state.userHasVerified = status;
                    })
                );
            },
            setAgreementStatus: (status: boolean) => {
                set(
                    produce((state: any) => {
                        state.userAgreementStatus = status;
                    })
                );
            },
            clearSession: () =>
                set(
                    produce((state: any) => {
                        state.user = null;
                        state.accessToken = "";
                        state.userInfo = null;
                        state.userAgreementStatus = null;
                        state.userHasVerified = false;
                    })
                ),
            setUserInfo: (userInfo: any) => {
                set(
                    produce((state: any) => {
                        state.userInfo = userInfo;
                    })
                );
            },
            setUser: (session: any) => {
                return set(
                    produce((state: any) => {
                        state.user = session?.user;
                        state.accessToken = session?.accessToken;
                    })
                );
            }
        }),
        {
            name: "user-storage", // Persisted key for storage
            storage: createJSONStorage(() => encryptedStorage) // Custom encrypted storage
        }
    ) as any,
    //{ name: "user-broadcast-channel" } // Broadcasting across tabs with unique channel
) as any
//);

export default useUserStore;

