import { create } from "zustand";
import { produce } from "immer";

interface AccountMessagesStoreProps {
    accountMessages: any;
    clearAccountMessages: () => void;
    setAccountMessages: (notifications: any) => void;
}

const useAccountMessagesStore = create<AccountMessagesStoreProps>((set, get) => ({
    accountMessages: [],
    setAccountMessages: (messages: any) =>
        set(
            produce((state: any) => {
                state.accountMessages = messages
            })
        ),
    clearAccountMessages: () => set({ accountMessages: [] })
})
)

export default useAccountMessagesStore;